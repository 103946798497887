.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: inherit !important;
}

@keyframes fadeOut {
  0% {
    background-color: rgb(159, 201, 255);
  }
  100% {
    background-color: #ffffff;
  }
}

.fade-out {
  animation: fadeOut 5s ease-in-out;
}

.thumbnail-size canvas {
  width: 100% !important; /* Override any default styles */
  height: auto !important; /* Maintain aspect ratio */
  aspect-ratio: 1.3;
  scale: 1;
  object-fit: cover;
  object-position: top;
}

.thumbnail-size-page canvas {
  width: 100% !important; /* Override any default styles */
  height: auto !important; /* Maintain aspect ratio */
  aspect-ratio: 1/1.414;
  object-fit: cover;
  object-position: top;
}
