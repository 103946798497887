.App {
  text-align: center;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  height: 10px; /* Height of the horizontal scrollbar */
}

/* Track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background: transparent; /* Light background */
  border-radius: 4px; /* Rounded corners */
}

/* Handle (the draggable scrolling element) */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24); /* Darker color for contrast */
  border-radius: 4px; /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5); /* Slightly darker on hover for feedback */
}
