.pdfOverlay-popover .ant-popover-inner {
  border: 1px solid #2563eb; /* Adjust the border color as needed */
  max-width: 30rem;
  padding-left: 0;
  padding-right: 0;
}

.pdfOverlay-popover .ant-popover-title {
  font-size: 12px; /* Adjust the font size as needed */
  color: gray; /* Change the title color to gray */
}

/* Custom CSS file or style tag */
.custom-textarea .ant-input {
  background-color: transparent !important; /* Remove background */
  border: none !important; /* Remove border */
  box-shadow: none !important; /* Remove shadow */
  resize: none !important; /* Disable resizing */
}

.custom-textarea .ant-input:focus {
  box-shadow: none !important; /* Remove focus outline */
  background-color: transparent !important; /* Remove background */
  border: none !important; /* Remove border */
  outline: none !important;
}

/* In your CSS file */
.custom-textarea .ant-input-textarea:hover {
  background-color: transparent !important;
  border: none !important; /* Remove border */
  outline: none !important;
}

/* Override Ant Design styles for the TextArea component */
.custom-textarea.ant-input-textarea,
.custom-textarea.ant-input-textarea:focus,
.custom-textarea.ant-input-textarea:hover,
.custom-textarea.ant-input-textarea:focus .custom-textarea.ant-input,
.custom-textarea.ant-input:focus {
  background-color: transparent !important; /* Ensure background stays transparent */
  border-color: transparent !important; /* Adjust the border color as needed */
  box-shadow: none !important; /* Remove any focus shadows if needed */
  border: none !important;
  transition: none !important; /* Remove transition effects */
  outline: none !important;
  border-radius: 0% !important;
  outline-color: transparent !important;
}

[contentEditable][data-placeholder]:empty:before {
  content: attr(data-placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  color: #adb5bd;
}

.custom-progress-bar .ant-progress-bg {
  background-color: white !important;
}

.custom-progress-bar .ant-progress-inner {
  background-color: #ffffff50 !important;
}

.custom-progress-bar .ant-progress-text {
  color: white !important;
}

.document-item {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  margin: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
