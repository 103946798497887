@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  img {
    @apply inline-block;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

body {
  font-family: 'WantedSans', sans-serif;
}

.backdrop-blur {
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px); /* For Safari */
}

.grid-home-component .frames-drop>* {
  -webkit-animation: changeOpacity 2s ease forwards infinite 5s;
  animation: changeOpacity 2s ease forwards infinite 5s;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.grid-home-component .frames-icons>span:nth-child(1) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@keyframes fadeAndSlideDown {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(100px); /* Adjust this value based on how far you want the icons to move */
  }
}

.frames-icons span {
  animation: fadeAndSlideDown 2s ease-in-out forwards infinite;
}

.frames-icons span:nth-child(1) {
  animation-delay: 0s;
}

.frames-icons span:nth-child(2) {
  animation-delay: 0.5s; /* Starts half a second after the first icon */
}

.frames-icons span:nth-child(3) {
  animation-delay: 1s; /* Starts one second after the first icon */
}

@font-face {
  font-family: "WantedSans";
  src: url("https://s3.traddocs.com/landing/fonts/WantedSans-Regular.woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "WantedSans";
  src: url("https://s3.traddocs.com/landing/fonts/WantedSans-Medium.woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "WantedSans";
  src: url("https://s3.traddocs.com/landing/fonts/WantedSans-SemiBold.woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "WantedSans";
  src: url("https://s3.traddocs.com/landing/fonts/WantedSans-Bold.woff2");
  font-weight: 700;
  font-style: normal;
}